import { Injectable } from '@angular/core';
import { ApiResponse, ResponseStatus } from 'src/app/models/common.model';
import { HttpService } from 'src/app/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpService:HttpService) { }

  login(credentials) {
    return this.httpService.post("Auth/Login", credentials)
  }

  loginWithToken(credentials) {
    return this.httpService.post("Auth/loginWithToken", credentials)
  }

  getCompanyInfo() {
    return this.httpService.get("Company/GetCompanyInfo",{})
  }

  forgotPassword(body: any) {
    return this.httpService.post("Auth/ForgotPassword", body);
  }

  getLoggedInUserProfile() {
    return this.httpService.get("Auth/GetLoggedInUserProfile", {});
  }

  getNotification(body: any) {
    return this.httpService.post("Notification/GetMultipleNotificationsRecords", body);
  }

  updateProfile(body: any) {
    return this.httpService.post("Auth/EditUser", body);
  }

  changePassword(body: any) {
    return this.httpService.post("Auth/ChangePassword", body);
  }

  updateProPic(body: any) {
    return this.httpService.postfile("Auth/UpdateUserProfilePicture", body);
  }

  verifyEmail(params: any){
    return this.httpService.post('Auth/VerifyEmail', params);
  }

  verifyMobile(params: any){
    return this.httpService.post('Auth/VerifyMobile', params);
  }


  confirmEmailWithOTP(body ,params: any){
    return this.httpService.postWithParams('Auth/ConfirmEmailWithOTP', body,params);
  }

  confirmMobileWithOTP(body ,params: any){
    return this.httpService.postWithParams('Auth/ConfirmMobileWithOTP', body,params);
  }

}
