import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-notification-popover',
  templateUrl: './notification-popover.component.html',
  styleUrls: ['./notification-popover.component.scss'],
})
export class NotificationPopoverComponent implements OnInit {
  @Input() notifications: any;


  constructor(public router:Router,private popover:PopoverController) { }

  ngOnInit() {
  }

  viewAll(){
    this.popover.dismiss();
    this.router.navigate(['view-notifications'])
  }

}
