
export type HandledErrorType = { message: string, action: string, description?: string };

export const API_ERRORS: { [status: number]: HandledErrorType } =
{
    "0": { message: "Connection Error", action: 'Retry', description: "Please Check Internet Connection and Try Again" },
    400: { message: "Bad Request", action: '' },
    401: { message: "Session Expired", action: 'Login', description: "Your Session is expired. Please Login Again" },
    404: { message: "Not Found", action: '', description: "Requested Resource Not Found!" },
    403: { message: "Access forbidden!", action: '', description: "You are not allowed to access requested resource!" },
    500: { message: "Internal Server Error", action: '', description: "Internal Server Error! Please try after some time." },
}