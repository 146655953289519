import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

  constructor() { }

  localStore(key: any, value: any) {
    localStorage.setItem(key, value ? typeof value == 'object' ? JSON.stringify(value) : value : '');
  }

  getLocal(key: any) {
    return localStorage.getItem(key);
  }

  removeLocal(key: any) {
    return localStorage.removeItem(key);
  }

  clear() {
    return localStorage.clear();
  }
}
