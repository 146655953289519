import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Observable, tap } from 'rxjs';
import { ApiResponse } from '../models/common.model';

@Injectable()
export class HttpService {

  apiBaseUrl: string = environment.apiBaseUrl;
  user: any;
  constructor(private http: HttpClient, public router: Router) {

  }

  post(method: string, body: any, params: any = {}): Observable<ApiResponse> {
    const url = this.apiBaseUrl + method;
    body = this.trimData(body);
    return this.http.post(url, body, {}) as Observable<ApiResponse>;
  }

  postWithParams(method: string, body: any, params: any = {}) {
    const url = this.apiBaseUrl + method;
    body = this.trimData(body);
    return this.http.post(url, body, {
      params: params
    }) as Observable<ApiResponse>;
  }

  get(method: string, params: any) {
    const url = this.apiBaseUrl + method;
    return this.http.get(url, {

    }) as Observable<ApiResponse>;
  }

  postWithoutToken(method: string, body: any, params: any = {}) {
    const url = this.apiBaseUrl + method;
    body = this.trimData(body);
    return this.http.post(url, body, {
    });
  }

  postfile(method: string, body: any, params: any = {}) {
    const url = this.apiBaseUrl + method;
    body = this.trimData(body);
    return this.http.post(url, body, {}) as Observable<ApiResponse>;
  }

  trimData(out: any) {
    // console.log("trim typeof out", typeof out, out);
    if (out) {
      if (typeof out == "object" && !Array.isArray(out)) {

        Object.keys(out || {}).forEach(key => {
          if (typeof out == "object") {
            out[key] = this.trimData(out[key]);
          }
          else {
            out[key] =
              out[key] && typeof out[key] == 'string' ?
                out[key].trim() :
                out[key];// trim strings only
          }
        })
      }
      else if (typeof out == "object" && Array.isArray(out)) {

        out.forEach(object => {
          if (typeof out == "object") {
            object = this.trimData(object);
          }
          else {
            object =
              object && typeof object == 'string' ?
                object.trim() :
                object;// trim strings only
          }
        })
      }
      else if (typeof out == "string") {
        out = out.trim();
      }
    }

    return out;
  };



  getJson = (method: string, options = null): Observable<Object> => this.http.get(this.apiBaseUrl + method, { responseType: 'json' });



  postData(url: string, data, params: any = undefined) {
    data = this.trimData(data);
    let headers: HttpHeaders = new HttpHeaders();
    headers.set('Accept', 'application/json, text/plain');
    headers.set('Content-type', 'application/json');
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.request('POST', url, { body: data, headers: headers, params: params });
  }

  postFormData(url: string, formData: FormData) {
    let headers: HttpHeaders = new HttpHeaders();
    headers.set('Accept', 'application/json, text/plain');
    headers.set('Content-type', 'application/json');
    let options = {
      headers: headers
    };
    return this.http.post(url, formData, options);
  }


  postDriverData(url: string, data, attachment) {
    data = this.trimData(data);
    return this.postDataWithAttachment(url, data, attachment);
  }

  postDataWithAttachment(url: string, data: any, attachment: any, reportProgress: boolean = false) {
    data = this.trimData(data);
    return this.postDataAsFormData(url, data, attachment, "data", "attachment", reportProgress);
  }

  postDataWithAttachmentLabel(url: string, data: any, attachment: any, dataLabel, reportProgress: boolean = false) {
    data = this.trimData(data);
    return this.postDataAsFormData(url, data, attachment, dataLabel, "attachment", reportProgress);
  }

  postDataAsFormData(url: string, data: any, attachment: any, dataLabel: string = "data", attachmentLabel: string = 'attachment', reportProgress: boolean = false) {
    data = this.trimData(data);
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Accept', 'text/plain');
    headers.append('Content-Type', 'multipart/form-data');
    const formData: FormData = new FormData();
    if (data) {
      formData.append(dataLabel, JSON.stringify(data));
    }
    if (attachment) {
      formData.append(attachmentLabel, attachment);
      delete data[attachmentLabel];
    }
    return this.http.request('POST', url, { body: formData, headers: headers, reportProgress: reportProgress, observe: reportProgress ? 'events' : 'body' }).pipe(tap(e => {
      console.log(e);
    }));
  }


}
